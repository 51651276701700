import React from "react";
import Assets from "../Layouts/Assets";
import Style from "./ThankYouModal.module.scss"

const ThankYouModal = ({successMessage}) => {
  return (
    <div className={`${Style.popup_warp} px-4`}>
      <img src={Assets.success} alt="sucess" />
      <p className={`${Style.popup_content} mb-0`}>{successMessage}</p>
    </div>
  );
};

export default ThankYouModal;
