import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../Logic/Dimensions";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  logOut,
} from "../store/slices/authSlice";

import { DeleteCoverPhoto } from "../store/slices/userprofileSlice";
import imageCompression from "browser-image-compression";

import {
  coverPhotoUpload,
  RemoveCoverPhoto,
} from "../store/slices/coverPhotoUploadSlice";
import { useProfile } from "../Logic/useProfile";
import { toast } from "react-toastify";
import UploadImageCrop from "./useUploadImageCrop";

export const useCoverPhoto = (newImg) => {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const [IsCoverPhotoDeleting, setIsCoverPhotoDeleting] = useState(false);
  const [IsCoverPhotoUploading, setIsCoverPhotoUploading] = useState(false);
  const [tempCoverPhotoObj, setTempCoverPhotoObj] = useState({});
  const inputFile = useRef(null);
  const inputFileForm = useRef(null);
  const { replaceCoverPhotoObj } = useProfile();
  const [showCoverCroper, setCoverCroper] = useState(false);

  const [coverPhoto, setCoverPhoto] = useState();
  const onButtonClick = () => {
    setCoverCroper(true);
  };

  const CoverButtonLabel = (data) => {
    switch (data) {
      case 1:
        return "Upload cover photo";
        break;
      case 2:
        return "Edit cover photo";
        break;
      case 3:
        return "Uploading...";
        break;
      case 4:
        return "Upload cover";
        break;
      case 5:
        return "Removing...";
        break;
      case 6:
        return "Remove photo";
        break;
      default:
        return "";
    }
  };

  const options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const handleDragOverCover = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOutCover = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDropCover = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const compressedFile = await imageCompression(e?.dataTransfer?.files[0], {
      ...options,
      alwaysKeepResolution: true,
    });
    setIsCoverPhotoUploading(true);
    dispatch(
      coverPhotoUpload({
        photo: compressedFile,
      })
    )
      .unwrap()
      .then((res) => {
        const { success, result } = res?.data;
        if (success && success !== undefined) {
          inputFileForm?.current?.reset?.();
          replaceCoverPhotoObj(result?.src);
          setIsCoverPhotoUploading(false);
        }
      });
  };

  const handleDragCoverNew = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOutCoverNew = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  var imageObj;

  const handleDropNew = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const fileName = "myFile.jpg";
    if (newImg) {
      fetch(newImg).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], fileName, { contentType });
        // access file here

        var callbackUrl = (url) => {
          setCoverPhoto(url);
        };
        imageObj = new UploadImageCrop(file, callbackUrl);
        imageObj.setCropRerImage(imgRef);
      });
    }
    if (e?.dataTransfer?.files?.length == 1) {
      var callbackUrl = (url) => {
        setCoverPhoto(url);
      };
      imageObj = new UploadImageCrop(e.dataTransfer.files[0], callbackUrl);
      imageObj.setCropRerImage(imgRef);
    }

    if (e?.target?.files?.length == 1) {
      var callbackUrl = (url) => {
        setCoverPhoto(url);
      };
      imageObj = new UploadImageCrop(e?.target?.files[0], callbackUrl);
      imageObj.setCropRerImage(imgRef);
    }
  };

  const UploadCover = async () => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(inputFile.current.files[0].name)) {
      toast.warning(
        "Please upload file having extensions .jpeg/.jpg/.png/.gif only!",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      inputFileForm?.current?.reset();
      return false;
    } else {
      if (inputFile?.current?.files[0]) {
        const file = inputFile.current.files[0];
        const fileURL = URL.createObjectURL(file);
        setCoverPhoto(fileURL);

        if (fileURL) {
          // setCoverCroper(true);
        }
        // You can use this fileURL as needed, for example:
        // setPreviewURL(fileURL);
      }
      // const compressedFile = await imageCompression(
      //   inputFile?.current?.files[0],
      //   {
      //     ...options,
      //     alwaysKeepResolution: true,
      //   }
      // );
      // setIsCoverPhotoUploading(true);
      // dispatch(
      //   coverPhotoUpload({
      //     photo: compressedFile,
      //   })
      // )
      //   .unwrap()
      //   .then((res) => {
      //     const { success, result } = res?.data;
      //     if (success && success !== undefined) {
      //       inputFileForm?.current?.reset?.();
      //       replaceCoverPhotoObj(result?.src);
      //       setIsCoverPhotoUploading(false);
      //     }
      //   });
    }
  };

  const uploadCoverPhoto = async (image) => {
    const compressedFile = await imageCompression(image, {
      ...options,
      alwaysKeepResolution: true,
    });
    setIsCoverPhotoUploading(true);
    dispatch(
      coverPhotoUpload({
        photo: compressedFile,
      })
    )
      .unwrap()
      .then((res) => {
        const { success, result } = res?.data;
        if (success && success !== undefined) {
          inputFileForm?.current?.reset?.();
          replaceCoverPhotoObj(result?.src);
          setIsCoverPhotoUploading(false);
          setCoverCroper(false);
          setCoverPhoto("");
        }
      });
  };

  const TriggerRemoveCoverPhoto = () => {
    setIsCoverPhotoDeleting(true);
    dispatch(RemoveCoverPhoto())
      .unwrap()
      .then((res) => {
        const { success, result } = res?.data;
        if (success && success !== undefined) {
          dispatch(DeleteCoverPhoto());
          setIsCoverPhotoDeleting(false);
          setCoverCroper(false);
        }
      });
  };
  return {
    inputFile,
    inputFileForm,
    onButtonClick,
    UploadCover,
    TriggerRemoveCoverPhoto,
    IsCoverPhotoUploading,
    setIsCoverPhotoDeleting,
    IsCoverPhotoDeleting,
    setIsCoverPhotoUploading,
    CoverButtonLabel,
    handleDragOverCover,
    handleDragOutCover,
    handleDropCover,
    showCoverCroper,
    setCoverCroper,
    coverPhoto,
    uploadCoverPhoto,
    handleDropNew,
    handleDragOutCoverNew,
    handleDragCoverNew,
    setCoverPhoto,
  };
};
