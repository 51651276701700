import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";
import RegisterModal from "../../components/Modals/RegisterModal";

const initialState = {
  plansData: null,
  AddOnData:null,
  testimonialData: null,
  browsePhotosData: null,
  liveStatusData: null,
  status: "idle",
  AddOnStatus:"idle",
  registeredCountriesStatus: "idle",
  message: null,
  availableLanguage: [],
  registeredCountries: []
};

export const getPlansData = createAsyncThunk(
  "home/getPlansData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    let token = localStorage.getItem('USER_ACCESS_TOKEN')
    let url = "list-plans"
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAddOnData = createAsyncThunk(
  "home/getAddOnData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    let token = localStorage.getItem('USER_ACCESS_TOKEN')
    let url = "add-on-list"
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getLanguages = createAsyncThunk(
  "userpreference/getLanguages",
  async (user_id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`list-languages`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTestimonials = createAsyncThunk(
  "home/testimonials",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("list-testimonials");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBrowsePhotos = createAsyncThunk(
  "home/browsePhotos",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("list-images");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatus = createAsyncThunk(
  "home/getStatus",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("count");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getFlagsList = createAsyncThunk(
  "home/getFlagsList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("list-registered-countries");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    [getPlansData.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [getPlansData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.plansData = action.payload.result;
    },
    [getPlansData.rejected]: (state, action) => {
      state.status = "error";
    },
    [getAddOnData.pending]: (state, action) => {
      state.AddOnStatus = "loading";
      state.message = "";
    },
    [getAddOnData.fulfilled]: (state, action) => {
      state.AddOnStatus = "succeed";
      state.AddOnData = action.payload.result;
    },
    [getAddOnData.rejected]: (state, action) => {
      state.AddOnStatus = "error";
    },
    [getTestimonials.pending]: (state, action) => {
      state.status = "loading";
    },
    [getTestimonials.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.testimonialData = action.payload.result;
    },
    [getTestimonials.rejected]: (state, action) => {
      state.status = "error";
    },
    [getBrowsePhotos.pending]: (state, action) => {
      state.status = "loading";
    },
    [getBrowsePhotos.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.browsePhotosData = action.payload.result;
    },
    [getBrowsePhotos.rejected]: (state, action) => {
      state.status = "error";
    },
    [getStatus.pending]: (state, action) => {
      state.status = "loading";
    },
    [getStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.liveStatusData = action.payload.result;
    },
    [getStatus.rejected]: (state, action) => {
      state.status = "error";
    },
    [getLanguages.pending]: (state) => { },
    [getLanguages.fulfilled]: (state, action) => {
      state.availableLanguage = action.payload.data?.result;
    },
    [getLanguages.rejected]: (state, action) => { },


    [getFlagsList.pending]: (state, action) => {
      state.registeredCountriesStatus = "loading";
    },
    [getFlagsList.fulfilled]: (state, action) => {
      state.registeredCountriesStatus = "succeed";
      state.registeredCountries = action.payload.result;
    },
    [getFlagsList.rejected]: (state, action) => {
      state.registeredCountriesStatus = "error";
    },
  },
});

export default homeSlice.reducer;
