import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  inititatePayment,
  closeModal,
  paymentCompletion,
  paymentModalAction,
  updateConfig,
} from "../../store/slices/checkoutSlice";
import { updateActivePlanAfterPusrchase } from "../../store/slices/userprofileSlice";
import { getAxiosInstance } from "../../api/index";
import { toast } from "react-toastify";

export const usePlans = () => {
  const dispatch = useDispatch();
  const { REACT_SECRET_KEY } = process.env;
  const [message, setMessage] = useState(null);
  const { status, data, paymentCompleteStatus } = useSelector(
    (state) => state.payment
  );
  const [selectedId, setSelectedId] = useState(null);
  const [country, setCountry] = useState([]);

  useEffect(() => {
    getCountryDialCodes();
  }, []);

  const getCountryDialCodes = async () => {
    const api = await getAxiosInstance();
    try {
      let data = await api.get("/country-dial-codes");
      setCountry(data?.data?.result);
    } catch (error) {
      setCountry([]);
    }
  };
  function loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.async = true;
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error("Script load error"));
      document.head.appendChild(script);
    });
  }
  async function handleRazorPaySubmit(values, result, type) {
    if (!result?.order_id) {
      toast.error("Something went wrong");
      // setEasyPayStates((state) => ({ ...state, isPaying: false }));
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?");
      // setEasyPayStates((state) => ({ ...state, isPaying: false }));
      return;
    }
    const options = {
      key: REACT_SECRET_KEY,
      amount: result?.purchased_amount,
      currency: values?.plans_prices?.[0]?.countries?.currency_code,
      name: "Family Photos",
      description: "Test Transaction",
      order_id: result?.order_id,
      handler: async function (response) {
        if (response) {
          const body = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            type: type,
            // razor_status: "success",
            // plan_id: easyPayStates?.packageDetail?.id ?? null,
            // offer_amount: easyPayStates?.offerAmount ?? null,
            // payment_id: "",
            // offer_code: easyPayStates?.offerCode,
            // total_amount: "50000",
          };
          if (!response?.razorpay_payment_id) {
            confirmPaymentAfterSuccess(body, "error");
            // setMessage(error.message);
          } else if (response?.razorpay_payment_id) {
            // setMessage("Payment Status: "  + "🎉");
            // setIsPaymentSuccess(true);
            confirmPaymentAfterSuccess(body, "success", type);
          } else {
            setMessage("An unexpected error occurred.");
          }
          // verifyRazorPay(body)
          //   .then((resp) => {
          //     if (resp?.data?.status_code === 200) {
          //       router.push("/payment-receipt/success");
          //     } else {
          //       // toast.error("Payment error");
          //       router.push("/payment-receipt/failure");
          //     }
          //   })
          //   .finally(() => {
          //     setEasyPayStates((state) => ({ ...state, isPaying: false }));
          //   });
        }
      },
      modal: {
        ondismiss: function () {
          // setEasyPayStates((state) => ({ ...state, isPaying: false }));
          toast.error("Payment failed");
        },
      },
      theme: { color: "#950053" },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  const handleInitialtePayment = async (item, details, type) => {
    setSelectedId(item?.id);
    let data = {
      plan_id: item?.id ?? "",
      country_id: item?.plans_prices?.[0]?.countries?.id ?? "",
      currency: item?.plans_prices?.[0]?.countries?.currency_code ?? "",
      type: type,
      ...details,
    };
    // dispatch(inititatePayment(data));
    const resultAction = await dispatch(inititatePayment(data));
    if (inititatePayment.fulfilled.match(resultAction)) {
      handleRazorPaySubmit(item, resultAction?.payload?.data?.result, type);
    }
  };

  const closePaymentModal = () => {
    dispatch(paymentModalAction(false));
  };

  const confirmPaymentAfterSuccess = async (body, type, paymentType) => {
    const resultAction = await dispatch(paymentCompletion(body));
    if (paymentCompletion.fulfilled.match(resultAction)&&resultAction?.payload?.data?.success) {
      dispatch(
        updateActivePlanAfterPusrchase(
          resultAction.payload.data?.result?.activated_plan
        )
      );
      if (type == "error") {
        setTimeout(() => {
          setMessage(null);
        }, 3000);
      }
      if (type == "success") {
        if (paymentType === "Addon") {
          closePaymentModal();
          dispatch(updateConfig((state) => (state.thankYouPopup = true)));
          setTimeout(() => {
            dispatch(updateConfig((state) => (state.thankYouPopup = false)));
          }, 3000);
        } else {
          setTimeout(() => {
            // closePaymentModal()
            window.location = "/get-started";
            // navigate("/dashboard");
          }, 1000);
        }
      }
    } else{
      closePaymentModal()
      toast.error("This plan is not available in your country.");
    }
  };

  return {
    handleInitialtePayment,
    status,
    selectedId,
    closePaymentModal,
    confirmPaymentAfterSuccess,
    paymentCompleteStatus,
    country,
    message,
    setMessage,
  };
};
